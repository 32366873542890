import React from 'react';
import './Header2.scss';
import styled, { css,keyframes } from "styled-components";

// import SvgLines from 'react-mt-svg-lines';  

const Header = ({actionTitle, image, detailedActionTitle,dasharray}) => {

 const dash = keyframes`
    from { stroke-dashoffset: 2802;}
    to {stroke-dashoffset: 0; }`

const animation = props =>
  css`${dash} 3s ease-out forwards`

const PulseButton = styled.path`
  animation: ${animation};
  stroke-dasharray: ${dasharray};

`

	return (<>

		<div className="header2_container" >

			<div className="izquierda">
                 
            <svg viewBox="0 0 400 400" width="100%" height="100%">
               <PulseButton 
                  d={image}
                  fill='none'
                  stroke={randomColor()}
                  strokeWidth={1}
               />
            </svg>

				{/* <img  src={image} alt="galera" /> */}
			</div>

			<div className="derecha">

			<div className="wrapper">
					<div className="wrapper-inner">
						<div className="scroll-down">
							<span className="arrow-down"></span>
							<span className="scroll-title"> </span>
						</div>
					</div>
				</div>
				<div className="slogan_container">
					<h1>{actionTitle}</h1>
					<h2>{detailedActionTitle}</h2>
				</div>			
			</div>
		</div>
	</>)
}

export default Header;



// var style = {
//    border: '16px solid #eee',
//    borderTop: '16px solid #3ae',
//    borderRadius: '50%',
//    width: '1cm',
//    height: '1cm',
//    animation: 'spin 2s linear infinite',
// }

const randomColor = () =>{
   var colorArray=[
     "#F58E49",
     "#E86D71",
     "#BC618C",
     "#805E91",
     "#4A577C",
      ]

      return colorArray[getRandomInt(0,5)];
   
}


function getRandomInt(min, max) {
   min = Math.ceil(min);
   max = Math.floor(max);
   return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
 }