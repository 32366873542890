export function getEvents() {

   return new Promise((resolve) => {
      fetch('https://sheets.googleapis.com/v4/spreadsheets/1QVoBzwz65JlQ_nB4cnedddCarka8R4DEkt__T_taC4E/values/agenda?alt=json&key=AIzaSyAMec_DMfk_l2lOCpsK0ie3e91k01OnefY')
      .then(function (response){return response.json()})
      .then(data => (ObjectConstructor(data.values)))
      .then(data => resolve(data))
     
   })
}
  


// export function getEvents() {

//     var id="1QVoBzwz65JlQ_nB4cnedddCarka8R4DEkt__T_taC4E";
//     var sheet="2"

//     return new Promise((resolve) => {
//         fetch('https://spreadsheets.google.com/feeds/list/'+id+'/'+sheet+'/public/full?alt=json')
//             .then(response => response.json())
//             .then(data => data.feed.entry)
//             .then(data => ObjectConstructor(data))
//             .then(data => resolve(data))
//     })
// }


const ObjectConstructor = (data) => {
    let array = [];

   
    for (var i = 1; i < data.length; i++) {
        array.push({
            "id":       data[i][0],
            "nombre":   data[i][2],
            "fecha":    data[i][1],
            "detalle":  data[i][4],
            "img":      data[i][5],
            "entrada":  data[i][3],
            "dias": convertirFecha(data[i][1])
        })
        if (i === data.length - 1) {
            return (array)
        }
    }
}



//////////////// HANDLER DE FECHAS
const convertirFecha = (fecha) => {
    var dateParts = fecha.split("/");
    var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]); 
   return Math.ceil(Math.abs(new Date() - dateObject) / (1000 * 60 * 60 * 24)); ; 
}