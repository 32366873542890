import React from 'react';
import './Integrantes.scss';


const Integrantes = ({ nombre, cargo, img }) => {


    return (<>

        <div className="card">
            <div className="opacit">
                <div className="container" style={{ backgroundImage: "url(" + img + ")" }}>
                    <div className="opacit"></div>
                </div>
                <h2 className="nombre">{nombre}</h2>
                <h3 className="posicion">{cargo}</h3>

            </div>
        </div>

    </>)
}
export default Integrantes;

