import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes} from '@fortawesome/free-solid-svg-icons'
import { faCalendar} from '@fortawesome/free-solid-svg-icons'

import './AgendaDetail.scss';


const AgendaDetail = ({evento, showAgendaDetail}) => {


	return (<>
      <div className="overlay">
      
        <div className="agenda_detail_container">
        <FontAwesomeIcon className="close_icon" style={{color: "grey"}} size="10px" icon={faTimes} onClick={() =>{showAgendaDetail(false)}}/>
        <button className="close" onClick={() =>{showAgendaDetail(false)}}>
            
            </button>

            <div style={{ backgroundImage: "url(" + evento.img + ")" }} className="agenda_detail_item_image"></div>
            
            <div className="agenda_detail_item_description">
            
                <h1>{evento.nombre}</h1>
                <h2>{evento.detalle}</h2>

                <div className="agenda_detail_item_detail">

                    <div className="agenda_detail_item_date">
                        <FontAwesomeIcon style={{color: "grey"}} icon={faCalendar}  />
                        <h2>{evento.fecha}</h2>
                    </div>

                    <div className="agenda_detail_item_fee">
                           <FontAwesomeIcon style={{color: "grey"}} icon={faTimes} />
                           <h2>{evento.entrada}</h2>
                    </div>
                </div>

            </div>
            


        </div>
        </div>
	
	</>)
}

export default AgendaDetail;

