import React, {useEffect,useState} from "react";
import "./PartnerListContainer.scss";
import {getSocios} from "../../Backend/socios.js";
import PartnerCard from "../PartnerCard/PartnerCard";
import MyLoader from "../MyLoader/MyLoader";

const PartnerListContainer = ({peek, filter}) => {

    const [peekQuantity, setPeekQuantity]=useState(peek);
    const [loading, setLoading] = useState(true);
    const [socios, setSocios] = useState([]);
    
   

	useEffect(() => {
      //   setLoading(true);
		   getSocios()
         .then(result => { 
            
            if (filter==="Todos"){
                setSocios(result)
            }
            else if (filter==="Peek"){
                setSocios(result.splice(0,8));
            }
            else{peekQuantity 
                ? setSocios(peekArray(result,peekQuantity))
                : setSocios(filterArray(result,filter));
            }
            setLoading(false);
            setPeekQuantity(false);
		})

	}, [filter, peekQuantity] );


	return ( 
	<>
	
		<div className = "listaDeSocios_container" > 
		 	{loading 
			 ? <MyLoader/>
			 :<> {socios.map(socio => <PartnerCard key={socio.id} socio = {socio}/>)} </>
		} </div> 
		
	</>
	);
};

export default PartnerListContainer;


//peek function get random partner cards
const peekArray= (array, peeknumber) => {
    var randomArray =[];
    for (var i = 0; i < peeknumber; i++) {
        var rand = array[Math.floor(Math.random() * array.length)];
        randomArray.push(rand);
    }
    return randomArray;
}


//filter socios by type
const filterArray= (array, value) => {
    return array.filter(element => element.type ===value);
}
