import React from 'react'

import WhatsAppWidget from 'react-whatsapp-widget'
import 'react-whatsapp-widget/dist/index.css'

const App = () => {

    return <WhatsAppWidget
        phoneNumber='+5491154763159'
        companyName='Camara Gastronomica de Quilmes'
        message='Comunicate con un representante de la camara'
        sendButton='Enviar'

    />
}

export default App

