import React, {useEffect,useState} from "react";
import "./PartnerListSection.scss";
import {getCategories} from "../../Backend/socios.js";
import MyLoader from "../MyLoader/MyLoader";
import PartnerListContainer from "../PartnerListContainer/PartnerListContainer";

const PartnerListSection = () => {
    const [name, setName] = useState("Todos");
    const [categories, setCategories] = useState([]);

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const [loading, setLoading] = useState(true);


	useEffect(() => {
		setLoading(false);
		getCategories().then((result) => {
           setCategories(result);
           setLoading(false);
		})

	},[name] );



	return ( 
	<>
		<div className = "partner_section" >
		   
            <div className="filter_data">
                <h2> Filtra por tipo:</h2>
                    { loading ? <MyLoader/>: 
                        <select onChange={handleNameChange}>
                        {categories.map(categoria =>
                            <>
                                <option className="option" value={categoria}>{categoria}</option>
                            </>)}
                        </select>
                    }
                
            </div>


            <div className="partner_list_container_parent">
		        <PartnerListContainer filter={name}/> 
            </div>
		</div> 
	</>
	);
};

export default PartnerListSection;
