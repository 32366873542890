import React, {useEffect,useState} from "react";
import "./PartnerList.scss";
import {getSocios} from "../../Backend/socios.js";
import PartnerListContainer from "../PartnerListContainer/PartnerListContainer";
import {Link} from "react-router-dom";

const PartnerList = () => {

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setLoading(false);
		getSocios().then((result) => {
	
			setLoading(false);
		})

	}, [loading]);


	return ( 
	<>
		<div className = "lsc" >
		    <h1 className = "section_header" > Nuestros asociados </h1>
		    <h2> Conoce a los miembros de la camara </h2> 
		    <PartnerListContainer peek={8} filter={"Peek"} /> 
		    <Link className = "link_button link_button_partner_list2" to = {"/partnerlist"}>Todos los socios </Link>
		</div> 
	</>
	);
};

export default PartnerList;
