import React, {useEffect, useState} from 'react';
import {getEvents} from '../../Backend/agenda';
import { Link } from 'react-router-dom';
import './AgendaPeek.scss';


const Agenda = () => {

	const [loading, setLoading]=useState(true);
	const [events, setEvents]=useState([]);
	useEffect(() => {
		setLoading(false);
		getEvents().then((result) => {
			setEvents(result);
			
			setLoading(false);
		})

	}, []);


	return (<>

		<div className="agenda_peek_container">
			<h1>Agenda Gastronomica</h1>
			<h2>Novedades! </h2>


			{loading ? "hols" :
			<div className="agenda_container_grid">
				{events.map(evento =>
					<div key={evento.img} className="agenda_container_item">
						<div style={{ backgroundImage: "url(" + evento.img + ")" }} className="agenda_item_image">
						</div>
						<div className="agenda_item_footer">
							<h2>{evento.nombre}</h2>
							<p>{evento.nombre}</p>
						</div>
					</div>
				)}
			</div>
		}
			
			<Link className="link_button link_button_agenda" to={'/agenda'}>Agenda Completa</Link>

		</div>

	</>)
}

export default Agenda;