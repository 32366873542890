import React from 'react';
import './QuienesSomos.scss';
import Alejandro from '../../images/organigrama/AlejandroLarumbre.jpg';
import GustavoM from '../../images/organigrama/GustavoMallo.jpg';
import EduardoS from '../../images/organigrama/EduardoSpagnoleta.jpg';
import PabloE from '../../images/organigrama/PabloEntin.jpg';
import FernandoA from '../../images/organigrama/FernandoAieta.jpg'
import FedericoC from '../../images/organigrama/FedericoCapitani.jpg'
import GabrielS from '../../images/organigrama/GabrielSavaro.jpg'
import SebastianS from '../../images/organigrama/SebastianScarvaci.jpg'
import SebastianD from '../../images/organigrama/SebastianDickson.jpg'
import Mariano from '../../images/organigrama/mariano.jpeg'
import Nayla from '../../images/organigrama/nayla.jpeg'
import SoledadS from '../../images/organigrama/SoledadSuarez.jpg'
import Integrantes from '../Integrantes/Integrantes'




const QuienesSomos = () => {

	const quienesSomos = {
		mision: "Somos una Asociación Civil sin fines de lucro que tiene como propósito  impulsar  el desarrollo de la gastronomía, hotelería y turismo local, acompañando el crecimiento de nuestros Socios.Siendo nexo con entidades públicas y privadas que incidan en los intereses del sector.",
		vision: "Ser la Cámara de gastronomía y turismo referente de Buenos Aires. Por la asistencia, acompañamiento y crecimiento de los socios que la componen, y vinculo de consulta de entidades publicas y privadas, y gestión de solución, siendo reconocidos por calidad, diversidad de propuestas e interacción con otros sectores. Generando nuevos empleos, y procesos de capacitación para el sector.",
		valores: " | Integridad | Confianza | Compromiso | Lealtad | Calidad | Servicio |"
	};

	return (<>



		<div className="mision_container">
			<div className="textContainer">
				<div className="abajo">
					<div className="valores">
						<h3>V a l o r e s</h3>
						<p>{quienesSomos.valores}</p>
					</div>
				</div>
				<div className="arriba">
					<div className="mision">
						<h3>M i s i o n</h3>
						<p>{quienesSomos.mision}</p>
					</div>
					<div className="vision">
						<h3>V i s i o n</h3>
						<p>{quienesSomos.vision}</p>
					</div>
				</div>
			</div>
		</div>


		<div className="organigrama_container" >
			<h2 className="section_header">Conocenos</h2>
			<div className="organigrama">
				<Integrantes img={Alejandro} nombre="Alejandre Larumbre" cargo="Presidente" />
				<Integrantes img={GustavoM} nombre="Gustavo Mallo" cargo="Vicepresidente" />
				{/* <Integrantes img={Cristian} nombre="Cristian Bernis" cargo="Vicepresidente Suplente" /> */}
				<Integrantes img={EduardoS} nombre="Eduardo Spagnoletta" cargo="Secretario. Subcomision de Foodtracks. Subcomision de Eventos" />
				<Integrantes img={PabloE} nombre="Pablo Entin" cargo="Tesorero" />
				{/* <Integrantes img={Mario} nombre="Mario D'Aquino" cargo="Secretario Suplente" /> */}
				{/* <Integrantes img={Hombre} nombre="Paul Wright" cargo="Revisor de Cuentas 1" /> */}
				{/* <Integrantes img={Miguel} nombre="Miguel Ramenghi" cargo="Revisor de Cuentas 2" /> */}
				<Integrantes img={FernandoA} nombre="Fernando Aieta" cargo="2do vocal, subcomision de Relaciones institucionales" />
				<Integrantes img={FedericoC} nombre="Federico Capitani" cargo="Primer Vocal, Subcomision de seguridad, subcomision de bares y cervecerias" />
				<Integrantes img={GabrielS} nombre="Gabriel Savaro" cargo="Revisor de cuentas" />
				<Integrantes img={SebastianS} nombre="Sebastian Scarvaci," cargo="Primer revisor de cuentas. Subcomision de Proveedores y Prestadores de servicios" />
				<Integrantes img={SebastianD} nombre="Sebastian Dickson," cargo="Subcomision de asistencia social, Subcomision de Proveedores y prestadores de servicios. Subcomision de relaciones institucionales Subcomision de Relaciones institucionales"/>
				<Integrantes img={SoledadS} nombre="Soledad Suarez" cargo="Subcomision de eventos" />				
				
				{/* <Integrantes img={Mariana} nombre="Mariana Confalonieri" cargo="Secretaria" /> */}
				<Integrantes img={Nayla} nombre="Nayla Dudzinskas" cargo="Subcomisión de Genero" />
				<Integrantes img={Mariano} nombre="Mariano Valenzuela" cargo="Subcomisión de Tecnología" />
				{/* <Integrantes img={Veronica} nombre="Verónica Nahabedian" cargo="Abogada" /> */}
			</div>
		</div>
	</>
	)
}

export default QuienesSomos;