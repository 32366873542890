import React from "react"
import './MyLoader.scss'


const MyLoader = (props) => (
      <>
            <div className="loader"></div>
      </>
)

export default MyLoader