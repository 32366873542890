export function getSocios() {

   return new Promise((resolve) => {
      fetch('https://sheets.googleapis.com/v4/spreadsheets/1QVoBzwz65JlQ_nB4cnedddCarka8R4DEkt__T_taC4E/values/lista_de_socios?alt=json&key=AIzaSyAMec_DMfk_l2lOCpsK0ie3e91k01OnefY')
      .then(function (response){return response.json()})
      .then(data => resolve (ObjectConstructor(data.values)))
     
   })
}
  


//AUXILIAR FUNCTIONS
const EliminateDuplicates = (names) => names.filter((v, i) => names.indexOf(v) === i)


const ObjectConstructor = (data) => {
   var array = [];
   for (var i = 1; i <= data.length; i++) {
      array.push({
        "id":       data[i][0],
        "name":     data[i][1],
        "type":     data[i][2],
        "location": data[i][3],
        "img":      data[i][4],
      })
      if (i === data.length-1 ) {

        return (array);
      }
   }
}













// const ObjectConstructorCategory = (data) => {
//     var array = [{type:"Todos"}];

//     for (var i = 0; i < data.length; i++) {
//         array.push({
//             "type":  data[i].gsx$tipo.$t,
//         })
//         if (i === data.length - 1) {
//             console.log("aca esta el array "+ array);
//             return (array)
//         }
//     }
// }







export function getCategories() {
   
  
   return new Promise((resolve) => {
       fetch('https://sheets.googleapis.com/v4/spreadsheets/1QVoBzwz65JlQ_nB4cnedddCarka8R4DEkt__T_taC4E/values/lista_de_socios?alt=json&key=AIzaSyAMec_DMfk_l2lOCpsK0ie3e91k01OnefY')
       .then(function (response){return response.json();})
       .then(function (data){return data.values;})
       .then(data =>  ObjectConstructor(data))
        .then(data => resolve(EliminateDuplicates(data.map(element => (element.type)))))
        // .then(data => resolve(EliminateDuplicates(data)))
   })
}


