import React from "react";
import "./PartnerCard.scss";

const PartnerList = ({ socio}) => {
	return (
		<>
			<div className="flip-card">
				<div className="flip-card-inner">

					<div className="flip-card-front" style={{ backgroundImage: "url(" + socio.img + ")" }}>
						<div className="opacity">
							<h1 className="nombre_socio">{socio.name}</h1>
						</div>
					</div>

					<div className="flip-card-back">
						<h2 className="location">{socio.location}</h2>
						<h2  className="type">{socio.type}</h2>
						{/* <div className="webbtn">
							<button><FontAwesomeIcon icon={faGlobe} /></button>
						</div> */}
					</div>

				</div>
			</div>
		</>
	);
};

export default PartnerList;
