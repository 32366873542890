import React from 'react';
import emailjs from 'emailjs-com';
// import Footer from '../../Components/Footer/Footer'

import './ActionForm.scss';

export default function ContactUs() {

    function sendEmail(e) {
        e.preventDefault();

        emailjs.sendForm('llueveunmonton', 'template_84u28uj', e.target, 'user_GqWB6DWgQTHICnHQEnvCU')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
    }

    return (
        <div id="contacto">
            <div className="bell"></div>
            <div className="form">
                <div className='form_title'><h1>Contactanos</h1></div>
                <form className="contact-form" onSubmit={sendEmail}>

                    <input placeholder="Nombre" type="text" name="user_name" />

                    <input placeholder="Email" type="email" name="user_email" />

                    <textarea placeholder="Escriba un mensaje" type="text" name="message" />
                  
                    <button className="link_button link_button_form" type="submit" value="Send">Enviar</button>
          
                </form>
            </div>
            {/* <Footer /> */}
        </div>
    );
}

