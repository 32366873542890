import React from 'react';
import './Footer.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import logo_monocromatico_apaizado from '../../images/logo_monocromatico_apaizado.png';
import { Link } from 'react-router-dom';

const Footer = () => {


      return (<>
            <div className="footer_container">

                  <div className="logo_container">
                        <img className="logo" src={logo_monocromatico_apaizado} alt="logo" />
                  </div>

                  <div className="footer_menu">

                        <ul><Link style={{ textDecoration: 'none', color: 'white' }} to={'/'} >Home</Link></ul>
                        <ul><Link style={{ textDecoration: 'none', color: 'white' }} to={'/agenda'}  >Agenda</Link></ul>
                        <ul><Link style={{ textDecoration: 'none', color: 'white' }} to={'/partnerList'} >Socios</Link></ul>
                        <ul><Link style={{ textDecoration: 'none', color: 'white' }} to={'/quienessomos'}>Quienes Somos</Link></ul>
                        <ul><Link style={{ textDecoration: 'none', color: 'white' }} to={'/faq'}>Preguntas</Link></ul>

                  </div>

                  <div className="icon_container">
                        <FontAwesomeIcon className="icon" icon={faFacebook} />
                        <FontAwesomeIcon className="icon" icon={faInstagram} />
                  </div>
                  <div className="for_desktop">
                        <div className="despedida"><h1>
                              Ya viste todo, <br /> ahora sali a comer!
                        </h1></div>

                        <p>Quilmes, Buenos Aires, Argentina</p>

                  </div>
            </div>

      </>)
}

export default Footer;