
import React, { createContext, useContext, useState } from 'react'

const AppContext = createContext()
const useAppContext = () => useContext(AppContext)


export const AppProvider = ({ children }) => {

    const [menuIsOpen, setMenuIsOpen] = useState(false);

	const closeMenu = () =>{
		// window.scrollTo(0, 0);
		 menuIsOpen ? 	setMenuIsOpen(false) : 	setMenuIsOpen(true);
		// setMenuIsOpen(false);
		// alert("este es el valor de la varaible de context " + menuIsOpen);


		// alert("esto viene del context");
	}
      

    return (
        <AppContext.Provider value={{
				closeMenu,
                menuIsOpen
            }}>{children}</AppContext.Provider>)
}

export default useAppContext
