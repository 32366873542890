import React from 'react';
import './Faq.scss';
import {
      Accordion,
      AccordionItem,
      AccordionItemButton,
      AccordionItemHeading,
      AccordionItemPanel,
} from 'react-accessible-accordion';
import iniciotramite from '../../images/FAQ/iniciotramite.jpeg'
import docuhabilitacion from '../../images/FAQ/docuhabilitacion.jpeg'
import habilitacomercio from '../../images/FAQ/habilitacomercio.jpeg'
import protocolo1 from '../../images/FAQ/protocolo1.jpg'
import protocolo2 from '../../images/FAQ/protocolo2.jpg'
import protocolo3 from '../../images/FAQ/protocolo3.jpg'
/* import 'react-accessible-accordion/dist/fancy-example.css'; */
const FAQ = () => {


      return (<>

            <div className="faq_container">


                  <Accordion className="accordion">
                        <AccordionItem>
                              <AccordionItemHeading className="AccordionItemHeading">
                                    <AccordionItemButton>
                                          <h1>¿Cómo me asocio?</h1>
                                    </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                                    <p>
                                          Comunicándose con el número 11 5702-5586 de Secretaría.
                                          El primer paso es completar una planilla online con los datos del local y del titular, y el segundo paso es abonar la cuota inicial para poder ser dado de alta dentro de la cámara.
                                    </p>
                              </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                              <AccordionItemHeading className="AccordionItemHeading">
                                    <AccordionItemButton>
                                          <h1>¿Qué beneficios tengo como socio?</h1>
                                    </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                                    <p>
                                          ►Exención de Pago de Mesas y sillas desde mayo hasta agosto.<br />
                                          ►Distribuidoras P&G art. de limpieza 10% en el total.<br />
                                          ►Aadi Capif Bernal 20% descuento en abono mensual.<br />
                                          ►Rappi exclusivo 3 meses 0% 2 meses 10% 7mo hasta el final 16% + IVA. No exclusivo 2 meses 0% 2 meses 5% 2 meses 10% 2 meses 15% 9no hasta el final 18% + IVA.<br />
                                          ►Loveat 10% primeros 3 meses.<br />
                                          ►Pedidos YA EXCLUSIVO 18% + IVA.<br />
                                          ►Conoflex 35% del precio sobre el total de compra.<br />
                                          ►Capacitación permanente con canales de la municipalidad.<br />
                                          ►The Fork Trip Advisor sin comisión.<br />
                                          ►Ebenezer Bolsas por compras mayores a 10.000 unidades el 5% en bolsas de regalo.<br />
                                          ►Consulta sin cargo con la Abogada de la Cámara, el Contador de la Cámara, el Escribano de la Cámara, la Agencia de Marketing de la Cámara y arancel diferencial.<br />
                                          ►Recolección de basura con horarios y puntos fijos.<br />
                                          ►Subcomisión de Bares, Seguridad y Tecnología a disposición de todos los Socios para los temas inherentes.
                                    </p>
                              </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                              <AccordionItemHeading className="AccordionItemHeading">
                                    <AccordionItemButton>
                                          <h1>¿Cómo pago la cuota?</h1>
                                    </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                                    <p>
                                          La cuota mensual puede ser abonada mediante Transferencia Bancaria, Mercado Pago, en efectivo en el local de El Charro o solicitar que el cobrador pase a retirarla.
                                    </p>
                              </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                              <AccordionItemHeading className="AccordionItemHeading">
                                    <AccordionItemButton><h1>¿Dónde gestiono el Reba?</h1>

                                    </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                                    <p>
                                          Para gestionar su REBA debe primero solicitar un turno en mraciti@quilmes.gov.ar.
                                    </p>
                              </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                              <AccordionItemHeading className="AccordionItemHeading">
                                    <AccordionItemButton>
                                          <h1>¿Cómo conozco mi deuda de Seguridad & Higiene?</h1>
                                    </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                                    <p>
                                          Para conocer la deuda que posee de Seguridad & Higiene debe ingresar en https://arquivirtual.quilmes.gov.ar/municipal/Login_arqui.aspx, registrarse o iniciar sesión y consultar su estado de deuda.
                                    </p>
                              </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                              <AccordionItemHeading className="AccordionItemHeading">
                                    <AccordionItemButton>
                                          <h1>¿Dónde solicito poda de árboles?</h1>
                                    </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                                    <p>
                                          En caso de necesitar poda de árboles comunicarse al 4350 3000 y seguir las instrucciones que les vayan a mencionar.
                                    </p>
                              </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                              <AccordionItemHeading className="AccordionItemHeading">
                                    <AccordionItemButton>
                                          <h1>¿Dónde gestiono la libreta sanitaria?</h1>
                                    </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                                    <p>
                                          A partir de agosto 2021, obtené tu turno en la salita/dispensario más cercano.
                                    </p>
                              </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                              <AccordionItemHeading className="AccordionItemHeading">
                                    <AccordionItemButton>
                                          <h1>¿Cuál es el informe que debo tener de Bomberos y dónde lo solicito?</h1>
                                    </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                                    <p>
                                          
                                          INICIACION<br/>

                                          3 Copias xerográficas del plano de obra original entero (ósea sin cortar, ni pegar, ni reducir) escala 1 en 100 debe estar aprobado por el municipio correspondiente.<br/>

                                          1 Fotocopia de D.N.I. del titular (si es unipersonal). Copia del estatuto y sus últimas actas (en el caso de ser una sociedad) D.N.I. del socio gerente, apoderado o firmante de la sociedad.<br/>

                                          C.A.I.E. (certificado de aptitud de instalaciones eléctricas) visado por el colegio de técnicos de la provincia de Buenos Aires.<br/>

                                          1 Copia del contrato de alquiler, boleto compra-venta, escritura, comodato.<br/>

                                          Plan de evacuación.<br/>

                                          Abonar sellado según metros cuadrados.<br/>


                                          ASESORAMENTO<br/>

                                          Una vez iniciado el trámite con los requisitos antes mencionado, se espera a que la oficina técnica de bomberos nos entregue el llamado ASESORAMIENTO EN INCENDIO que constara con los condicionamientos a cumplir para la obtención del final de obra, ósea nos informaran cantidad, tipos y ubicación de matafuegos, luces de emergencias, carcelería, detectores de humos y mas según corresponda. En dicho informe veremos también reflejada la CAPACIDAD OCUPACIONAL del establecimiento.
                                          Una vez que cumplamos con lo solicitado, se coordina para la inspección y verificación.<br/>


                                          FINAL DE OBRA<br/>

                                          Ya habiendo verificado el correcto cumplimiento de lo solicitado se obtiene el correspondiente FINAL DE OBRA, para lo cual debemos de abonar nuevamente un sellado del mismo valor que el de iniciación, el final de obra tiene validez por el término de 1 año. De haber algún tipo de modificación del lugar, se debe actualizar. Pasado el año se deberá de realizar el correspondiente mantenimiento.<br/>


                                          MANTENIMIENTO<br/>

                                          Para realizar el mantenimiento es necesario:obtiene el correspondiente FINAL DE OBRA, para lo cual debemos de abonar nuevamente un sellado del mismo valor que el de iniciación, el final de obra tiene validez por el término de 1 año. De haber algún tipo de modificación del lugar, se debe actualizar. Pasado el año se deberá de realizar el correspondiente mantenimiento.<br/>


                                          Medición de puesta a tierra.<br/>
                                          D.P.S. De matafuegos actualizados<br/>
                                          Capacitación en uso de extintores<br/>
                                          Se abonara un solo sellado según metros cuadrados


                                    </p>
                              </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                              <AccordionItemHeading className="AccordionItemHeading">
                                    <AccordionItemButton>
                                          <h1> ¿Dónde saco turno para habilitar mi local?</h1>
                                    </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                                    <img src={iniciotramite} alt="Inicio de tramite"></img>
                                    <img src={docuhabilitacion} alt="documentos para habilitacion"></img>
                                    <img src={habilitacomercio}alt="certificado de habilitacion"></img>
                              </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                              <AccordionItemHeading className="AccordionItemHeading">
                                    <AccordionItemButton>
                                          <h1> ¿Cuál es el PROTOCOLO OFICIAL?</h1>
                                    </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                              <img src={protocolo1} alt="hoja de protocolo 1"></img>
                              <img src={protocolo2} alt="hoja de protocolo 2"></img>
                              <img src={protocolo3} alt="hoja de protocolo 3"></img>
                              </AccordionItemPanel>
                        </AccordionItem>
                  </Accordion>
            </div>

      </>)
}

export default FAQ;