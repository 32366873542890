import React, {useEffect, useState} from 'react';
import {getEvents} from '../../Backend/agenda';

import MyLoader from "../MyLoader/MyLoader";
import AgendaDetail from "../AgendaDetail/AgendaDetail"
import './Agenda.scss';


const Agenda = () => {

	const [loading, setLoading]=useState(true);
	// const [eventsWeek, setEventsWeek]=useState([]);
	const [eventsMonth, setEventsMonth]=useState([]);
    const [showAgendaDetail, setShowAgendaDetail]=useState(false);
    const [selectedEvent, setSelectedEvent]=useState([]);


	useEffect(() => {
		setLoading(false);

		getEvents().then((result) => {
            
            // setEventsWeek(result.filter(element => element.dias<=30));
            setEventsMonth(result.filter(element => element.dias>0));
	
            setLoading(false);
        })

	}, []);


	return (<>
        
		<div className="agenda_container">
			<h1>Agenda Gastronomica</h1>


            <h2>Este Mes </h2>
            {showAgendaDetail ? <AgendaDetail evento={selectedEvent} showAgendaDetail={setShowAgendaDetail}/> : ""}
			{loading ?<MyLoader/>:
			<div className="agenda_container_grid">
				{eventsMonth.map(evento =>
                    
					<div key={evento.img} className="agenda_container_item">
                    <div className="agenda_container_item_overlay" onClick={() => {setShowAgendaDetail(true);setSelectedEvent(evento)}}/>
                  
						<div style={{ backgroundImage: "url(" + evento.img + ")" }} className="agenda_item_image">
						</div>
						<div className="agenda_item_footer">
							<h2>{evento.nombre}</h2>
                            <p>{evento.fecha}</p>
						</div>
					</div>
                   
				)}
                
			</div>
            }
            <div className="blank_space"></div>
		</div>
        
	</>)
}

export default Agenda;