import React from 'react';
import { Link } from 'react-router-dom';
import './Header3.scss';
import logo_colores from '../../images/logo_colores.png';
import styled, { css,keyframes } from "styled-components";



const Header = ({ actionTitle, display,  image, detailedActionTitle,dasharray }) => {

   const dash = keyframes`
   from { stroke-dashoffset: 2802;}
   to {stroke-dashoffset: 0; }`

const animation = props =>
 css`${dash} 3s ease-out forwards`

const PulseButton = styled.path`
 animation: ${animation};
 stroke-dasharray: ${dasharray};

`
	return (<>

		<div className="header1_container" >

			<div className="izquierda">
            {/* {
               display
               ?
               <img src={image} alt="cubiertos" />  
               :
               <></>
            } */}
				{/* <img src={image} alt="cubiertos" /> */}
            <svg viewBox="0 0 400 400" width="100%" height="100%">
               <PulseButton 
                  d={image}
                  fill='none'
                  stroke='#0c5ba6'
                  strokeWidth={0.8}
               />
            </svg>
			</div>


			<div className="derecha">

				<div className="wrapper">
					
					<div className="wrapper-inner">
						<div className="scroll-down">
							<span className="arrow-down"></span>
							<span className="scroll-title"> </span>
						</div>

					</div>

				</div>
				<div className="slogan_container">
               <div className="title_container">
               <h1>{actionTitle}</h1>
               <div className="logo_container">
					   <img className="logo" src={logo_colores} alt="galera" />
				   </div>
               
               
               </div>
					<h2>{detailedActionTitle}</h2>
					<div className="header_buttons">
						<Link className="link_button link_button_quienes_somos" to={'/quienessomos'}>Conocenos</Link>
						<a className="link_button link_button_quienes_somos" href={'/#contacto'}>Contactanos</a>
					</div>
				</div>

		



			</div>
		</div>
	</>)
}

export default Header;





